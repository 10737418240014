import {gql} from '@apollo/client'

import {CONTACT_FRAGMENT} from '../fragments/userData'

export const CONTACTS = gql`
  ${CONTACT_FRAGMENT}
  query contacts($everplanId: ID) {
    contacts(everplanId: $everplanId) {...ContactFragment}
  }
`

export const SCORE = gql`
  query score($userId: ID) {
    score(userId: $userId) {
      id
      total
      enabled
    }
  }
`
