import {fromJS, List, Map} from 'immutable'
import lodash from 'lodash'

import {sortContactsByCaseInsensitiveLabel} from './contactTools'

export const dasherizeImmutableData = immutableData => {
  if (List.isList(immutableData))
    return immutableData.map(data => dasherizeImmutableData(data))

  if (Map.isMap(immutableData)) {
    return immutableData.keySeq().reduce(
      (dasherized, key) => dasherized.set(lodash.kebabCase(key), dasherizeImmutableData(immutableData.get(key))),
      Map()
    )
  }

  return immutableData
}

export const cleanGraphQLResponse = (response, attribute, fallback = List()) => {
  if (response) {
    const graphQLResponse = response[attribute]

    return graphQLResponse ? dasherizeImmutableData(fromJS(graphQLResponse)) : fallback
  } else {
    return fallback
  }
}

export const cleanContactsGraphQLResponse = response => {
  if (response) {
    const graphQLResponse = response.contacts

    const contacts = graphQLResponse ? dasherizeImmutableData(fromJS(graphQLResponse)) : List()
    return sortContactsByCaseInsensitiveLabel(contacts.map(contact => contact.filter(contactAttributes => contactAttributes)))
  } else {
    return List()
  }
}

export const camelizeImmutableData = immutableData => {
  if (List.isList(immutableData))
    return immutableData.map(data => camelizeImmutableData(data))

  if (immutableData && Map.isMap(immutableData)) {
    return immutableData.keySeq().reduce(
      (dictionary, key) => dictionary.set(lodash.camelCase(key), camelizeImmutableData(immutableData.get(key))),
      Map()
    )
  }

  return immutableData
}
