import {gql} from '@apollo/client'


export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    city
    addressLine1
    addressLine2
    country
    region
    postalCode
  }
`

export const CONTACT_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  fragment ContactFragment on Contact {
    id
    everplanId
    firstName
    lastName
    middleName
    gender
    addresses {...AddressFragment}
    phoneNumbers
    birthdate
    birthdateMask
    emails
    companyName
    notes
    updatedAt
    website
  }
`
export const OWNER_OWNERSHIP_FRAGMENT = gql`
  fragment OwnerOwnershipFragment on OwnerOwnership {
    id
    deceased
    everplanId
    firstName
    lastName
    ownableId
    ownableType
  }
`
